import images from "../../../../assets/images";
import style from "../../../../styles/cards/HowCard.module.scss";

const HowCard = (props) => {
  return (
    <div className={style.card}>
      <div className={style.cardHeader}>
        <div className={style.imgWrap}>
          <img src={images?.how?.src} className={style.img} />
        </div>
      </div>
      <div className={style.cardContent}>
        <p className={style.subheader}>{props.data.subtitle}</p>
        <h3 className={style.header}>{props.data.title}</h3>
        <p className={style.desc}>{props.data.description}</p>
      </div>
    </div>
  );
};

export default HowCard;