import logo from "../public/images/logo/logo.png";
import logoLight from "../public/images/logo/logo_light.png";

import sample from "../public/images/care/sample.webp";
import soon from "../public/images/helpers/coming-soon.png";
import how from "../public/images/how/sample.png";
import home from "../public/images/home/sample.jpg";
import builder from "../public/images/carehomes/builder.png";

import bg_email from "../public/images/banner/email_bg.png";

import cqcDanger from "../public/icons/cqc_danger.png";
import cqcGrey from "../public/icons/cqc_grey.png";
import cqcStar from "../public/icons/cqc_star.png";
import cqcSuccess from "../public/icons/cqc_success.png";
import cqcWarn from "../public/icons/cqc_warn.png";

export default {
  logo,
  logoLight,
  sample,
  soon,
  how,
  home,
  bg_email,
  cqcDanger,
  cqcGrey,
  cqcStar,
  cqcSuccess,
  cqcWarn,
  builder
};
