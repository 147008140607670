import style from "../../../styles/sections/Why.module.scss";
import sectionStyle from "../../../styles/sections/SectionHeader.module.scss";
import { staticHowList } from "../../../data";
import HowCard from "../../UI/Cards/HowCard";

const How = () => {
  return (
    <section className={`${style.whyWrap} ${sectionStyle.sectionSecondary}`}>
      <div
        className={`${style.whyContainer} container-xl ${sectionStyle.sectionContainer}`}
      >
        <div className={style.content}>
          <div className={sectionStyle.sectionWrap}>
            <h2 className={sectionStyle.title}>How carefindr works?</h2>
            <p className={sectionStyle.subtitle}>
              Find your dream care home or retirement living property in just
              three simple steps...
            </p>
          </div>
          <div className={style.list}>
            {staticHowList.map((card, index) => (
              <HowCard key={index} data={card} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default How;
